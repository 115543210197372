<template>
    <div class="vx-row mb-12">
        <div v-bind:class="[
            detail ? detailShow + ' vertical-divider' : '',
            detailHide,
        ]">
            <div class="vx-row mb-12">
                
            </div>
            <data-table :responseData="responseData" :propsParams="params" :header="header"
                @reloadDataFromChild="reloadData">
                <template slot="thead">
                    <th width="7%">
                        Action
                    </th>
                </template>
                <template slot="tbody">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records" :class="tr.class">
                        <vs-td class="whitespace-no-wrap">
                            <template>
                                <div class="mt-4 flex justify-between">
                                    <vx-tooltip text="Show" class="mr-4">
                                        <vs-button type="line" icon-pack="feather" color="green" icon="icon-eye"
                                            v-on:click.stop="
                                                handleShow(tr)" />
                                    </vx-tooltip>
                                </div>
                            </template>

                        </vs-td>
                        <vs-td>
                            {{ tr.Code }}
                        </vs-td>
                        <vs-td>
                            {{ tr.OperatingUnitCode + " - " + tr.OperatingUnitName }}
                        </vs-td>
                        <vs-td>
                            <span v-if="tr.TransactionType == '01'"> AR Invoice </span>
                            <span v-else-if="tr.TransactionType == '02'"> Customer Deposit </span>
                            <span v-else-if="tr.TransactionType == '03'"> Credit Note </span>
                            <span v-else> - </span>
                        </vs-td>
                        <vs-td>
                            {{ tr.Note }}
                        </vs-td>
                        <vs-td>
                            {{ dateFormat(tr.CreatedAt) }}<br>
                        </vs-td>
                    </vs-tr>
                </template>
            </data-table>

            <div>
                <div>
                    <vs-prompt color="primary" title="Confirmation" @cancel="activePromptConfrm = false" @accept="doDelete"
                        @close="closePrompt" :active.sync="activePromptConfrm">
                        <div class="con-exemple-prompt">
                            Are you sure to delete ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                    <vs-prompt color="primary" title="Confirmation" @cancel="submitPromptConfirm = false" @accept="doSubmit"
                        @close="closePrompt" :active.sync="submitPromptConfirm">
                        <div class="con-exemple-prompt">
                            Are you sure to submit write-off ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                </div>
            </div>
            <!-- Approve Prompt -->

        </div>
        <!-- form -->
        <transition name="detail-fade">
            <div v-if="detail" v-bind:class="[
                detail ? 'vx-col md:w-5/6 w-full  mb-base' : '',
                detailHide,
            ]">
                <vs-row>
                    <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
                        vs-w="4">
                        <vs-button class="ml-auto" size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                            icon="icon-x-square">Close</vs-button>
                    </vs-col>
                    <vs-col vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                        <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                            icon="icon-x-square"></vs-button>
                    </vs-col>
                </vs-row>
            </div>
        </transition>
    </div>
</template>
<script lang="ts">
import moment from "moment"

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
    components: {
        
    },
    props: {
        selected: Object,
        option: Object,
    },
    data() {
        return {
            params: {
                search: "",
                length: 10,
                page: 1,
                order: "id",
                sort: "desc",
            },
            header: [
                {
                    text: "Code",
                    value: "code",
                    // width: '5%'
                },
                {
                    text: "Operating Unit",
                    value: "operating_unit",
                    // width: '5%'
                },
                {
                    text: "Transaction Type",
                    value: "transaction_type",
                    // width: '5%'
                },
                {
                    text: "Note",
                    value: "note",
                },
                {
                    text: "Created Date",
                    value: "created_at",
                }
            ],
            methods: ['', 'Cash', 'Giro', 'Cheque', 'Transfer'],
            responseData: {},
            detailShow: "vx-col md:w-1/6 w-full mb-base",
            detailHide: "core vx-col md:w-1/1 w-full mb-base",
            detail: false,
            notes: "",
            activePromptConfrm: false,
            submitPromptConfirm: false,
            approveOrReject: false,
            approvalPrompt: false,
            rejectPrompt: false,
            selectedData: {},
        };
    },
    computed: {},
    watch: {},
    mounted() {
        // this.reloadData(this.params);

        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        closePrompt() {
            this.activePromptConfrm = false
            this.submitPromptConfirm = false
        },
        dateFormat(value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        doDelete() {
            this.$vs.loading();
            this.$http.delete('/api/v1/write-off/' + this.selectedData.ID)
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        // this.$router.push("/finance/write-off");
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        doSubmit() {
            this.$vs.loading();
            this.$http.post('/api/v1/write-off/submit' + this.selectedData.ID)
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        // this.$router.push("/finance/write-off");
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        handleApprove(selected) {
            this.selectedData.class = ""
            this.approveOrReject = 1
            selected.class = "highlight"
            this.selectedData = selected
            this.rejectPrompt = false
            this.approvalPrompt = true
            console.log(this.selectedData)
        },
        handleReject(selected) {
            this.selectedData.class = ""
            this.approveOrReject = 0
            selected.class = "highlight"
            this.selectedData = selected
            this.approvalPrompt = false
            this.rejectPrompt = true
        },

        handleDelete(selected) {
            this.selectedData = selected
            this.activePromptConfrm = true
        },
        handleSubmit(selected) {
            this.selectedData = selected
            this.activePromptConfrm = true
        },
        closeDetail() {
            this.activePrompt2 = false;
            this.detail = false;
            this.selectedData.class = ""
            this.selectedData = {
                ID: 0,
            };
            this.reloadData(this.params)
        },
        dataTable(params) {
            return new Promise((resolve, reject) => {
                this.$http.get('/api/v1/write-off/data-table', {
                    params: {
                        status: 4,
                        is_view: 1,
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.sort,
                        sort: params.order,
                    }
                }).then((r) => {
                    resolve(r)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        create() {
            this.$router.push("/finance/write-off/create");

        },
        handleShow(selected) {
            this.$router.push({
                name: 'approval-show-write-off',
                // preserve current path and remove the first char to avoid the target URL starting with `//`
                params: { id: selected.ID },
                // preserve existing query and hash if any

            })
        },
        handleClose() {
            this.reloadData(this.params)
            this.selectedData.class = ""
            this.detail = false
            this.selectedData = {
                ID: 0,
            };
        },
        formatPrice(angka, prefix = "") {
            return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        reloadData(params) {
            this.params = params;
            console.log(params);
            this.$vs.loading();

            const dataTable = this.dataTable(params);
            dataTable.then((r) => {
                console.log(r);
                if (r.code == 500) {
                    this.$vs.loading.close();
                } else if (r.code == 200) {
                    this.$vs.loading.close();
                    this.data = r.data.records;
                    this.responseData = r.data;
                    this.responseData.length = r.data.length;
                    // this.checkedAll = false;
                } else {
                    this.$vs.loading.close();
                }
            });
        },
    },
};
</script>
<style scoped>
.nonfixed {
    position: inherit;
    padding-left: 20px;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
}

.highlight>td {
    background-color: #d0cdf0;
}

.colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
}

.vs-dialog {
    max-width: 1000px;
}
</style>