<template>
  <vx-card title="Write Off">
    <vs-tabs>
      <vs-tab label="Waiting Approval">
        <div class="tab-text">
          <waiting-approval></waiting-approval>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <waiting-execute></waiting-execute>
        </div>
      </vs-tab>
      <vs-tab label="Canceled / Rejected">
        <div class="tab-text">
          <reject></reject>
        </div>
      </vs-tab>
      
    </vs-tabs>
  </vx-card>
</template>
<script>
import WaitingApproval from './waiting-approval.vue';
import WaitingExecute from './waiting-execute.vue';
import Reject from './reject.vue';
export default {
  components: {
    
    "waiting-approval":WaitingApproval,
    "waiting-execute":WaitingExecute,
    "reject": Reject,
  }
};
</script>